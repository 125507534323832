import React from 'react';

import { graphql } from 'gatsby';

import DynamicPost from 'components/dynamic-post';
import Footer from 'components/dynamic-post/footer';
import BlogLatest from 'components/blog-latest';
import Header from 'components/dynamic-post/header';

import useStateWithLocalStorage from 'utils/localStorage';

const Post = (props) => {
  //PROPS
  const {
    data: {
      post: {
        title,
        slug,
        categories,
        post: { date, author, content, thumbImage },
      },
    },
  } = props;
  //PROPS

  const [value, setValue] = useStateWithLocalStorage('bookmarked');

  return (
    <>
      <article className="container container--xsmall">
        <Header
          data={{ title, date, author, image: thumbImage, slug }}
          value={value}
          setValue={setValue}
        />
        {content && <DynamicPost data={content} />}
        <Footer categories={categories.nodes} url="asd" />
      </article>
      <BlogLatest value={value} setValue={setValue} />
    </>
  );
};

export const query = graphql`
  query Post($id: String) {
    post: wpPost(id: { eq: $id }) {
      title
      id
      slug
      categories {
        nodes {
          slug
          name
          termTaxonomyId
        }
      }
      post {
        author {
          ... on WpColaborador {
            title
            slug
          }
        }
        date
        thumbImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        content {
          ... on WpPost_Post_Content_Title {
            fieldGroupName
            title
          }
          ... on WpPost_Post_Content_Text {
            fieldGroupName
            text
          }
          ... on WpPost_Post_Content_Image {
            fieldGroupName
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Post;
