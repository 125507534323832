import styled from 'styled-components';

import { breakpoints } from 'utils/mixins';

export const Wrapper = styled.section`
  position: relative;
  overflow: hidden;
  width: 100%;

  .gatsby-image-wrapper {
    width: 100%;

    & > div {
      height: ${(props) => (props.ratioMobile ? '0' : '')};
      padding-bottom: ${(props) => props.ratioMobile + '%'};
    }
  }

  @media ${breakpoints.tablet} {
    .gatsby-image-wrapper {
      & > div {
        height: ${(props) => (props.ratio ? '0' : '')};
        padding-bottom: ${(props) => props.ratio + '%'};
      }
    }
  }
`;
