import styled from "styled-components";
import { neutral, brand } from "theme/colors";
import { space } from "theme/sizes";
import { text_l, text_m, text_xl } from "theme/typography";
import { toRem } from "utils/mixins";

export const Wrapper = styled.section`
  margin-top: ${space.m};
  width: 100%;
`;
