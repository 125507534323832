import React from 'react';

import HTMLReactParser from 'html-react-parser';

import { Wrapper } from './TextBlock.styles';

const TextBlock = (props) => {
  const { data, blockquote } = props;

  return (
    <Wrapper blockquote={blockquote}>
      {data.text && HTMLReactParser(data.text)}
    </Wrapper>
  );
};

export default TextBlock;
