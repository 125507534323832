import styled from 'styled-components';

import { space } from 'theme/sizes';

export const Wrapper = styled.section`
  margin-top: ${(props) => (props.blockquote ? space.l : space.m)};
  text-align: start;
  padding: 0 ${space.s};

  p {
    font-style: ${(props) => props.blockquote && 'italic'};
    line-height: 1.2;
  }

  ul {
    list-style: disc;
    margin-bottom: 1em;

    li {
      margin-left: ${space.m};
    }
  }

  a {
    text-decoration: underline;
  }
`;
