import React from 'react';

import { BsBookmark, BsBookmarkFill } from 'react-icons/bs';

import Image from 'components/dynamic-post/image';

import { Wrapper } from './Header.styles';

const Header = (props) => {
  const {
    data: { image, title, date, author, slug },
    value,
    setValue,
  } = props;

  return (
    <Wrapper>
      <Image
        data={{ image }}
        ratio={(7 / 16) * 100}
        ratioMobile={(5 / 4) * 100}
      />
      <div className="article__header">
        {(date || author?.[0]) && (
          <p className="article__header-author">
            {author?.[0]?.title}
            {date && author?.[0] && ' • '}
            {date}
          </p>
        )}
        {value?.includes(slug) ? (
          <button
            className="article__header-bookmark"
            onClick={() => {
              setValue([
                ...value.filter((e) => {
                  return e !== slug;
                }),
              ]);
            }}
          >
            <BsBookmarkFill />
          </button>
        ) : (
          <button
            className="article__header-bookmark"
            onClick={() => setValue([...value, slug])}
          >
            <BsBookmark />
          </button>
        )}
      </div>
      <h1>{title}</h1>
    </Wrapper>
  );
};

export default Header;
