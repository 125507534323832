import React from 'react';

import { ButtonGeneric } from 'components/button';
import Share from 'components/share';

import { secondary } from 'theme/colors';

import { Wrapper } from './Footer.styles';

const Footer = (props) => {
  const { categories } = props;

  const COLORS = {
    'casos-sucesso': 'orange',
    'artigos-cientificos': 'green',
    desmistificacoes: 'red',
    'performance-empresarial': 'blue',
    'nutricao-alimentacao': 'yellow',
  };

  return (
    <Wrapper>
      <ul className="footer__categories">
        {categories.map((category, index) => {
          return (
            <li key={index}>
              <ButtonGeneric
                to={'/blog?categorias=' + category.slug}
                color={secondary[COLORS[category.slug]]}
              >
                {category.name || category.title}
              </ButtonGeneric>
            </li>
          );
        })}
      </ul>
      <Share />
    </Wrapper>
  );
};

export default Footer;
