import styled from 'styled-components';

import { space } from 'theme/sizes';
import { neutral } from 'theme/colors';

import { toRem } from 'utils/mixins';

export const Wrapper = styled.header`
  margin-top: ${space.header};

  h1,
  div {
    padding: 0 ${space.s};
  }

  h1 {
    font-size: ${toRem(56)};
    line-height: 1.2;
  }

  .article__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${space.xs};
    margin-bottom: ${space.xs};

    &-author {
      margin: 0;
      color: ${neutral.neu07};
    }

    &-bookmark {
    }
  }
`;
