import styled from 'styled-components';

import { container, space, fontSize } from 'theme/sizes';
import { neutral } from 'theme/colors';
import { toRem, breakpoints } from 'utils/mixins';

export const Wrapper = styled.ul`
  display: flex;

  li {
    &:not(:last-child) {
      margin-right: ${space.xxs};
    }

    a,
    button {
      background-color: ${neutral.neu11};
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      margin: 0;
      padding: ${space.xxs};
      border-radius: 100%;
    }
  }
`;
