import React from 'react';

// import flexible content modules
import Image from './image';
import Video from './video';
import TextBlock from './text-block';

const DynamicContent = (props) => {
  //PROPS
  const { data } = props;
  //PROPS

  if (!!data) {
    return data
      .filter((o) => o !== null)
      .map(({ fieldGroupName, ...rowData }, index) => {
        const type = fieldGroupName.split('_').slice(-1)[0];

        const components = {
          Text: TextBlock,
          Image,
        };

        const Component = components[type];

        let extraSettings = {};

        switch (type) {
          case 'BlocoDeImagem':
            extraSettings = {
              maxHeight: '400px',
            };
            break;
          case 'BlocoDeCitacao':
            extraSettings = {
              blockquote: true,
            };
            break;
        }
        return (
          Component && (
            <Component key={index} data={rowData} {...extraSettings} />
          )
        );
      });
  } else {
    return null;
  }
};

export default DynamicContent;
