import styled from 'styled-components';

import { space } from 'theme/sizes';
import { breakpoints } from 'utils/mixins';

export const Wrapper = styled.footer`
  margin-top: ${space.m};
  padding: 0 ${space.s};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  li {
    margin-bottom: ${space.xxs};
  }

  .footer__categories {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${space.xs};

    li {
      display: inline;
      margin-right: ${space.xxs};
    }
  }

  @media ${breakpoints.phablet} {
    flex-wrap: unset;

    .footer__categories {
      margin-bottom: unset;
    }
  }
`;
