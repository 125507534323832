import React, { useEffect, useState } from 'react';

import { Article } from 'components/blog';

import { Wrapper } from './BlogLatest.styles';

const BlogLatest = (props) => {
  const { value, setValue } = props;

  const [postsData, setPostsData] = useState([]);

  const getServerData = async () => {
    const res = await fetch(`${process.env.GATSBY_WP}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
        query posts {
          posts(first: 2, where: {orderby: {field: DATE, order: DESC}}) {
            edges {
              node {
                id
                title
                slug
                date
                categories {
                  nodes {
                    name
                    slug
                  }
                }
                post {
                  date
                  thumbText
                  thumbTitle
                  thumbImage {
                    altText
                    mediaItemUrl
                  }
                  author {
                    ... on Colaborador {
                      title
                      slug
                    }
                  }
                }
              }
            }
          }
        }
        
        `,
      }),
    });
    const data = await res.json();
    setPostsData(data.data.posts.edges);
  };

  useEffect(() => {
    getServerData();
  }, []);

  return (
    <Wrapper className="container container--xsmall">
      {postsData.map((article, index) => {
        return (
          <Article
            key={index}
            data={{ article, index: index + 1 }}
            imageUrl
            value={value}
            setValue={setValue}
          />
        );
      })}
    </Wrapper>
  );
};

export default BlogLatest;
