import styled from 'styled-components';

import { space } from 'theme/sizes';
import { breakpoints, gridSize } from 'utils/mixins';

export const Wrapper = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  margin-top: ${space.m};
  padding: 0 ${space.s};

  article {
    width: ${gridSize(8)};
  }

  @media ${breakpoints.tabletSpec} {
    flex-direction: row;

    article {
      width: ${gridSize(4)};
    }
  }
`;
