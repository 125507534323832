import React from 'react';
import {
  FaLink,
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaEnvelope,
} from 'react-icons/fa';

import { Wrapper } from './Share.styles';

import { copyTextToClipboard } from 'utils/copyToClipboard';

const Share = (props) => {
  //PROPS
  const { url, title } = props;
  //PROPS

  return (
    <Wrapper>
      <li>
        <a
          target="_blank"
          href={`https://www.facebook.com/share.php?u=${url}`}
          title="Facebook"
          rel="noopener noreferrer"
        >
          <FaFacebookF />
        </a>
      </li>
      <li>
        <a
          target="_blank"
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
          title="LinkedIn"
          rel="noopener noreferrer"
        >
          <FaLinkedinIn />
        </a>
      </li>
      <li>
        <a
          target="_blank"
          href={`https://twitter.com/intent/tweet?url=${url}`}
          title="Twitter"
          rel="noopener noreferrer"
        >
          <FaTwitter />
        </a>
      </li>
      <li>
        <a
          href={`mailto:?subject=${title}&body=Acho que vais gostar deste post da ShapeShifters - <a style="cursor:pointer" href="${url}">${url}</a>`}
          title="Email"
        >
          <FaEnvelope />
        </a>
      </li>
      <li>
        <button
          onClick={() => copyTextToClipboard(url)}
          aria-label="Copy Post Uri"
        >
          <FaLink />
        </button>
      </li>
    </Wrapper>
  );
};

export default Share;
